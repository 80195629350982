@import 'styles/common.scss';

.filterModal {
  background: rgba(9, 20, 37, 0.4);
  z-index: 200;
}
.container {
  @include medium {
    position: relative;
    width: 460px;
    max-height: 90%;
  }
  @include small {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-radius: 20px 20px 0 0;
  }
  @include large {
    position: relative;
    width: 460px;
    max-height: 90%;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: #CED0D3;
  }
  &::-webkit-scrollbar-track {
    margin-block: 5px;
    background-color: var(--bg-primary-webapp);
    border-radius: 6px;
    @include small {
      margin-block: 16px;
      background: var(--bg-primary);
    }
  }
  .cancelIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: var(--dark-grey);
    z-index: $zindex-modal;
    @include small {
      background: #f8f7f7;
      border-radius: 50%;
      padding: 9px;
    }
  }
}
