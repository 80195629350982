@import 'styles/common.scss';

$placeholder-color: rgba(9, 20, 37, 0.5);

.SingleSelect {
  position: relative;
  display: inline-flex;
  white-space: nowrap;
  width: 100%;
  @include flex-column(null, null);
  .trigger {
    @include f-inter-regular(1.5rem, 2.2rem);
    @include flex(center);
    position: relative;
    width: 100%;
    background: var(--bg-primary);
    border-radius: 8px;
    padding: 10px 40px 10px 16px;
    border: none;
    overflow: hidden;
    white-space: nowrap;
    border: 1px solid var(--border-color);
    @include sm-desktop-up {
      border: none;
    }
    cursor: pointer;
    &:focus {
      outline: none;
    }
    .selectedValue {
      color: var(--black-color);
    }
    .buttonLabel {
      color: $placeholder-color;
    }
    .chevron {
      color: rgba(9, 20, 37, 0.74);
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      transition: transform 0.2s;
      &Reverse {
        transform: translateY(-50%) rotateX(180deg);
      }
    }
  }
  &Dropdown {
    position: relative;
    background: var(--bg-primary);
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 1px 20px rgba(20, 30, 46, 0.12);
    z-index: $zindex-dropdown;
    min-width: 272px;
    max-width: 100%;
    top: -45px;
    margin-bottom: -40px;
    &Top {
      top: auto;
      bottom: calc(100% - 30px);
    }
    .options {
      height: auto;
      max-height: 288px;
      overflow: auto;
      border: none;
      padding: 0;
      margin: 0;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: var(--scroll-bar-color);
        box-shadow: 0 0 1px rgba($color: $black, $alpha: 0.5);
      }
    }
    .option {
      @include f-inter-regular(1.5rem, 2.2rem);
      position: relative;
      color: var(--black-color);
      padding: 9px 0 9px 16px;
      width: 100%;
      cursor: default;
      vertical-align: bottom;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-transform: capitalize;
      cursor: pointer;
      &:first-of-type {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      &:last-of-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      &:hover {
        background: rgba(100, 101, 104, 0.05);
      }

      &:focus {
        outline: 0;
      }
      .checkMark {
        display: block;
        color: var(--primary-color);
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
      }

      &.groupEnd {
        border-bottom: 1px solid #ced0d4;
      }
      .deleteLabel {
        color: var(--complementary-secondary);
      }
    }
  }
}
