@import 'styles/common.scss';

.Filter {
  background: var(--bg-primary-webapp);
  @include small {
    background: var(--bg-primary);
  }
  .content {
    @include medium {
      padding: 12px 28px 28px 28px;
    }
    @include small {
      padding: 20px;
    }
    @include large {
      padding: 12px 28px 28px 28px;
    }
  }
  .title {
    @include f-inter-semi-bold(1.6rem, 1.8rem);
    text-align: center;
    margin-bottom: 28px;
    &Clear {
      position: absolute;
      color: var(--dark-grey);
      @include f-inter-regular(1.3rem, 1.5rem);
      border: none;
      background: none;
    }
  }
  .back {
    position: absolute;
    top: 12px;
    left: 10px;
    @include flex-center;
    color: var(--dark-grey);
    cursor: pointer;
    @include small {
      background: #f8f7f7;
      border-radius: 50%;
    }
  }
  .list {
    background: var(--bg-primary);
    border-radius: 16px;
    padding: 16px;
    min-height: 135px;
    margin-bottom: 28px;
    @include small {
      padding: 0;
      margin: 0;
      min-height: 100px;
    }
    div:last-of-type {
      hr {
        display: none;
      }
    }
    &Row {
      @include flex(center, space-between);
      cursor: pointer;
      column-gap: 20px;
      padding: 8px 0;
      margin: 8px 0;
      .verified {
        padding: 0;
        &:hover {
          background: none;
        }
      }
    }
    &Multiselectchip {
      background: transparent;
      padding: 0;
    }

    .selection {
      &Checkbox {
        margin-right: 8px;
        margin-bottom: 8px;
        &:last-of-type {
          margin: 0;
        }
      }
      &Tickmark {
        cursor: pointer;
        &Separator {
          border: none;
          border-bottom: 1px dashed #ced0d4;
        }
        div:last-of-type {
          hr {
            display: none;
          }
        }
      }
    }
    .spinner {
      position: absolute;
      top: 35%;
      left: 45%;
    }
    &Values {
      @include flex-center;
      min-width: 0;
    }
    .chevronIcon {
      min-width: 24px;
    }
  }
  .input {
    flex: 1;
    margin: 16px 0;
    input {
      border-radius: 20px;
      border: none;
      background: rgba(100, 101, 104, 0.1);
      padding: 11.5px 48px;
      width: 100%;
      @include f-inter-regular(1.7rem, 2.1rem);
      &:focus {
        border: none;
        caret-color: var(--primary-color);
      }
    }
  }
  .separator {
    border: none;
    border-bottom: 1px dashed #ced0d4;
    margin: 0;
  }
  .name {
    @include f-inter-semi-bold(1.6rem, 2.2rem);
    white-space: nowrap;
  }
  .values {
    color: var(--dark-grey);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .footer {
    position: sticky;
    bottom: 0;
    @include small {
      padding: 0 20px 20px;
    }
    &Separator {
      border-bottom: 1px solid #ababab;
    }
    &Buttons {
      @include flex(center, space-between);
      background: var(--bg-primary);
      @include medium {
        padding: 28px;
      }
      @include large {
        padding: 28px;
      }
      .clear {
        color: var(--dark-grey);
        @include f-inter-regular(1.3rem, 1.5rem);
        border: none;
        background: none;
        cursor: pointer;
      }
      .result {
        @include f-inter-semi-bold(1.6rem, 2.2rem);
        border-radius: 14px;
        padding: 11px;
      }
      &Mobile {
        padding: 12px 10px;
      }
    }
    .filtering {
      color: var(--accent-text);
      background: #80cac8;
      border-radius: 14px;
      @include medium {
        padding: 11px 24px;
      }
      @include small {
        @include f-inter-bold(1.8rem, 2.8rem);
        text-align: center;
        padding: 12px 0;
      }
      @include large {
        padding: 11px 24px;
      }
    }
  }
}
