@import 'styles/common.scss';

.modal {
  background: rgba(9, 20, 37, 0.4);
}
.loader {
  @include flex(center, center);
  min-height: 300px;
}
.container {
  position: relative;
  max-height: 90%;
  @include mobile {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-radius: 20px 20px 0 0;
  }
  @include desktop {
    width: 460px;
  }
  .content {
    @include small {
      padding: 20px;
    }
    @include large {
      padding: 28px;
    }
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: #CED0D3;
  }
  &::-webkit-scrollbar-track {
    margin-block: 5px;
    background-color: var(--bg-primary-webapp);
    border-radius: 6px;
    @include small {
      margin-block: 16px;
      background: var(--bg-primary);
    }
  }
  .cancelIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    color: var(--primary-color-disabled);
    z-index: $zindex-modal;
  }
  &Verify {
    @include mobile {
      padding: 32px 16px 16px;
    }
    @include desktop {
      padding: 56px 48px;
    }
  }
}
