@import 'styles/common.scss';

.ApplyConfirmation {
  @include flex-column(center, center);
  .emoji {
    @include f-inter-regular(3.2rem, 5.2rem);
  }
  .title {
    @include f-inter-bold(1.8rem, 2.2rem);
    padding-bottom: 32px;
  }
  .option {
    position: relative;
    @include f-inter-semi-bold(1.6rem, 2.2rem);
    color: var(--text-primary);
    background: #f6f6fa;
    border-radius: 12px;
    margin-bottom: 8px;
    padding: 15px;
    & > span {
      position: absolute;
      left: 16px;
    }
    &Text {
      @include f-inter-semi-bold(1.3rem, 1.6rem);
      color: var(--dark-grey);
      margin-top: 8px;
    }
  }
}
