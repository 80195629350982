@import 'styles/common.scss';

$aside-width: 280;
$naviagtion-size-sm: 32px;
$naviagtion-size-lg: 60px;

.ManagerFilters {
  display: grid;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: 20px;
  margin-bottom: 32px;

  .dropdown {
    position: static;
    .dropdownButton {
      position: relative;
      text-transform: capitalize;
      background: var(--primary-color);
      color: var(--accent-text);
      border: 1px solid var(--primary-color);
      box-shadow: 0px 1px 2px rgba(1, 67, 66, 0.06);
      padding: 8px 40px 8px 14px;
      &:hover {
        box-shadow: 0px 2px 3px rgba(1, 67, 66, 0.07);
        background: var(--ui-teal-light-surface-brand-hover);
      }
      .startIcon {
        margin-right: 8px;
      }
      .icon {
        color: white;
      }
      & > span {
        @include f-inter-semi-bold(1.4rem, 2rem);
        color: var(--accent-text);
      }
    }

    & > ul {
      max-width: 160px;
      min-width: 160px;
      top: 55px;
      left: inherit;
    }
  }

  .dropdownV3 {
    position: static;

    .dropdownButtonSecond {
      background: var(--surface-surface-overlay-silent, rgba(8, 12, 11, 0.05));
      border: none;
      padding: 10px 40px 10px 14px;
      @include f-inter-semi-bold(1.4rem, 2rem);
      &:hover {
        background: var(
          --surface-surface-overlay-loud-plus,
          rgba(8, 12, 11, 0.15)
        );
      }

      &Selected {
        background-color: var(--primary-color);
        color: var(--accent-text);
        & > span {
          color: var(--accent-text);
          @include f-inter-semi-bold(1.4rem, 2rem);
        }

        .icon {
          color: white;
        }

        &:hover {
          box-shadow: 0px 2px 3px rgb(1 67 66 / 7%);
          background: var(--ui-teal-light-surface-brand-hover);
        }
      }
    }
    & > ul {
      max-width: 175px;
      min-width: 175px;
      top: 55px;
      left: inherit;
      @include small {
        left: 175px;
      }
    }
  }

  &Right {
    padding-right: 48px;
    margin-right: -40px;
    @include small {
      margin-right: -15px;
      padding-right: 23px;
    }
  }

  &Left {
    padding-left: 48px;
    margin-left: -40px;
    @include small {
      margin-left: -15px;
      padding-left: 23px;
    }
  }

  .seperator {
    border-right: 1px solid var(--border-border-15, rgba(8, 12, 11, 0.15));
    height: 18px;
    transform: rotate(180deg);
  }

  &Scrolled {
    position: fixed;
    top: 62px;
    padding: 12px 16px;
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    border-bottom: 1px solid
      var(--border-border-on-lights-15, rgba(8, 12, 11, 0.15));
    padding: 12px 16px;
    width: calc(100% - 271px);
  }

  &Wrapper {
    position: relative;
    .active {
      background: var(--surface-surface-overlay-silent, rgba(8, 12, 11, 0.085));
    }
  }
  &List {
    @include flex(center, space-between);
    gap: 8px;
    flex-wrap: nowrap;
    overflow-x: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  &List::-webkit-scrollbar {
    display: none;
  }

  &SlideShow {
    &Navigation {
      &Button {
        border-radius: 99px;
        background: var(--ui-teal-light-bg-primary);
        box-shadow: 0px 1px 3px 0px rgba(8, 12, 11, 0.05);
        padding: 9px;

        &:hover {
          border-radius: 50%;
        }

        @include small {
          background: var(--bg-primary);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        }
      }

      &Left {
        @include flex(center, flex-start);
        margin-right: 5px;
        left: 42px;
        height: 42px;
        width: 56px;
        background: linear-gradient(
          70deg,
          #eff1f3 39.14%,
          rgba(239, 241, 243, 13%) 100%
        );
        position: absolute;
        z-index: 1;
        @include small {
          left: 23px;
        }
        &Scrolled {
          left: 16px;
          background: linear-gradient(
            171deg,
            #fff 52.42%,
            rgba(255, 255, 255, 0) 100%
          );
          @include small {
            left: 7px;
          }
          & > button {
            border: 1.5px solid var(--border-border-15, rgba(8, 12, 11, 0.15));
          }
        }
      }

      &Right {
        margin-left: 5px;
        @include flex(center, flex-end);
        position: absolute;
        right: 42px;
        height: 42px;
        width: 56px;
        background: linear-gradient(
          270deg,
          #eff1f3 57.14%,
          rgba(239, 241, 243, 0) 100%
        );
        z-index: 1;
        @include small {
          right: 23px;
        }
        &Scrolled {
          right: 16px;
          background: linear-gradient(
            270deg,
            #fff 68.42%,
            rgba(255, 255, 255, 0) 100%
          );
          @include small {
            right: 7px;
          }
          & > button {
            border: 1.5px solid var(--border-border-15, rgba(8, 12, 11, 0.15));
          }
        }
      }
    }
  }

  .Multiselect {
    background: var(--surface-surface-overlay-silent, rgba(8, 12, 11, 0.05));
    &Wrapper {
      position: static;
    }
    border-radius: 10px;
    padding: 9px 15px;
    @include f-inter-semi-bold(1.4rem, 2rem);
    color: rgba(8, 12, 11, 1);
    p {
      white-space: nowrap;
    }

    &Selected {
      background-color: var(--primary-color);
      color: var(--accent-text);
    }
    &Open {
      background: var(
        --surface-surface-overlay-loud-plus,
        rgba(8, 12, 11, 0.15)
      );
    }
  }

  .multiDropdown {
    border: 0.5px solid var(--border-border-15, rgba(8, 12, 11, 0.15));
    box-shadow: 0px 4px 8px 0px rgba(8, 12, 11, 0.08);
    top: calc(100% + 5px);
    @include mobile {
      max-width: 360px;
    }
    & > ul {
      margin: 16px 0px;
      & > li {
        padding: 5px 12px;
      }
    }
    .checkbox {
      & > input {
        order: 1;
      }
    }
  }
}
