@import 'styles/common.scss';

.Manager {
  background: var(--bg-primary-webapp);
  @include flex-column;
  flex: 1;

  &Banner {
    @include small {
      position: relative;
      top: 0;
      z-index: 3;
    }
  }
  .menu {
    &Trigger {
      width: 15px;
      height: 15px;
    }
    &List {
      min-width: 150px;
      & > div {
        border: none;
        & > li {
          & > button > div {
            @include f-inter-regular-2(1.4rem, 2.2rem);
            color: var(--text-icon-text-icon-primary, #080c0b);
          }
        }
      }
    }
  }
  .icon {
    cursor: pointer;
    &Reverse {
      transform: rotateX(180deg);
    }
    &,
    &Reverse {
      transition: transform 0.2s;
    }
  }
  &Item {
    @include flex();
    flex-direction: column;
    margin-bottom: 32px;
    gap: 16px;
    &.bottomMargin12 {
      margin-bottom: 12px;
    }
    .title {
      @include flex();
      justify-content: space-between;
      align-items: baseline;
      p {
        display: inline-block;
        @include f-inter-bold(2rem, 3.8rem);
      }
      span {
        @include f-inter-regular-2(1rem, 1.5rem);
        color: var(--text-icon-text-icon-tertiary, rgba(8, 12, 11, 0.62));
        vertical-align: text-top;
        margin-left: 8px;
        padding: 1.5px 6px 2.5px 6px;
        border-radius: 100px;
        background: var(
          --surface-surface-overlay-silent,
          rgba(8, 12, 11, 0.05)
        );
      }
    }
  }
  .Title {
    @include f-inter-bold(2.8rem, 3.8rem);
  }
  .innerMain {
    width: 680px;
    margin: 0 auto;

    @include small {
      width: auto;
      margin: 0;
      padding: 16px 16px 96px 16px;
    }
  }

  .filterContainer {
    position: relative;
    top: 0;
    padding-top: 40px;
    transition: background 200ms;
    @include small {
      padding-top: 0px;
    }
    @include medium {
      background: var(--bg-primary-webapp);
      transition: background 200ms;
    }
  }

  .filter {
    @include flex(center, space-between);
  }
  .actions {
    @include flex(center);
    flex: 1;
  }
  .tabs {
    padding: 1px;
    input {
      cursor: pointer;
    }
    label {
      text-transform: capitalize;
    }
    &Container {
      padding: 18px 0 14px;
      &Scrolled {
        padding: 10px 0 7px;
      }
    }
  }
  .cancelContiner {
    margin-left: 12px;
    .cancelButton {
      @include f-inter-regular-2(1.6rem, 2.2rem);
      color: var(--text-icon-text-icon-interactive, #0c65e4);
      border: none;
      background: transparent;
      cursor: pointer;
    }
  }
  .search {
    height: 20px;
    width: 20px;
    &Input {
      flex: 1;
      input {
        border-radius: 20px;
        border: none;
        background: rgba(100, 101, 104, 0.1);
        padding: 12px 42px;
        width: 100%;
        @include f-inter-regular(1.6rem, 2rem);
        &:focus {
          border: none;
          caret-color: var(--primary-color);
        }
      }
      .startIcon {
        padding: 0;
        left: 5px;
        top: 48%;
      }
    }
  }
  .icons {
    @include flex(center);
    gap: 8px;
    &Button {
      @include flex();
      gap: 6px;
    }
    &Filter {
      width: 22px;
      height: 22px;
      & > svg {
        color: #080c0bbd;
      }
      &Disabled {
        & > svg {
          color: #080c0b4d;
        }
      }
      &Applied {
        & > svg {
          color: var(--primary-color);
        }
      }
      &Count {
        @include flex(center, center);
        @include f-inter-regular-2(1.1rem, 1.5rem);
        background: var(--surface-surface-brand, #009491);
        color: var(--text-icon-text-icon-inverted-primary, #fff);
        height: 20px;
        min-width: 20px;
        border-radius: 50%;
      }
    }
    .searchSelectedFilter {
      color: var(--primary-color);
      border-radius: 6px;
    }
  }

  .counts {
    &Row {
      padding: 12px 24px;
      @include f-inter-regular(1.4rem, 2rem);
      color: var(--text-icon-on-lights-tertiary);
    }
    &Button {
      @include f-inter-regular(1.4rem, 2rem);
      color: #0c65e4;
      padding: 8px;
    }
  }

  .listbox {
    position: relative;
    .button {
      @include f-inter-bold(2.4rem, 4rem);
      @include flex-center;
      background: none;
      color: var(--text-primary);
      padding-left: 10px;
      border: none;
      &:hover {
        background: none;
      }
      cursor: pointer;
      .icon {
        margin-left: 8px;
      }
    }
    .options {
      position: absolute;
      top: 100%;
      left: 0;
      background: #ffffff;
      box-shadow: 0px 1px 20px rgba(20, 30, 46, 0.12);
      border-radius: 12px;
      width: 220px;
      z-index: $zindex-dropdown;
      &:focus {
        outline: none;
      }

      .option {
        @include flex(center, space-between);
        @include f-inter(1.5rem, 2.2rem);
        color: var(--black-color);
        padding: 11px 16px;
        border-bottom: 1px solid #ced0d4;
        cursor: default;
        &:focus {
          outline: none;
        }
        &:last-of-type {
          border-bottom: none;
        }
        &:hover {
          background: rgba(100, 101, 104, 0.05);
        }
        .icon {
          display: none;
        }
        &[aria-selected='true'] {
          .icon {
            display: unset;
            color: var(--primary-color);
          }
        }
      }
    }
  }
  .touchpointCard {
    margin-bottom: 12px;
    &:last-of-type {
      margin-bottom: 0;
    }
    .touchCard {
      flex-wrap: nowrap;
    }
    .whiteCard {
      background-color: white;
      border-radius: 12px;
    }
    .savedAndStartedExpired {
      background-color: var(--surface-overlay-silent);
      border-radius: 12px;
    }
  }
  .expired {
    @include flex(center, space-between);
    cursor: pointer;
  }
  .cards {
    background: rgba(100, 101, 104, 0.05);
    padding: 0 16px;
    border-radius: 12px;
    &Expired {
      border-radius: 8px;
      background: var(--surface-surface-overlay-silent, rgba(8, 12, 11, 0.05));
      margin-bottom: 8px;
      &:last-of-type {
        border: none;
      }
    }
  }
  .subtitle {
    @include f-inter-bold(1.7rem, 2.2rem);
    padding-top: 26px;
    padding-bottom: 10px;
  }
  .chevron {
    color: rgba(9, 20, 37, 0.74);
  }
  .nodata {
    @include flex-column(center, center);
    flex: 1;
    height: 50vh;
    &Centered {
      height: 75vh;
    }
    &Emoji {
      @include f-inter-regular(4rem, 6.4rem);
    }
    &Title {
      @include f-inter-bold(2rem, 2.8rem);
      padding-top: 16px;
    }
    &Description {
      @include f-inter-regular(1.5rem, 2.1rem);
      text-align: center;
      color: var(--text-icon-text-icon-tertiary, rgba(8, 12, 11, 0.62));
      padding-top: 16px;
    }
    &ButtonGroup {
      padding-top: 16px;
      @include flex();
      gap: 8px;
    }
  }
  .logoLink {
    @include sm-desktop-down {
      display: none;
    }
    position: absolute;
    left: 7.5%;
    top: 24px;
  }
  .hamburger {
    @include sm-desktop-down {
      display: none;
    }
    position: absolute;
    right: 50px;
    top: 24px;
  }
}
.spinner {
  @include flex-center;
  width: 100%;
  height: 100%;
}

.spinnerHeight {
  @include flex-center;
  width: 100%;
  height: 200px;
}
.registrationModal {
  @include sm-desktop-down {
    bottom: 50px;
  }
}
