@import 'styles/common.scss';

.SignedInUser {
  position: relative;
  .content {
    @include flex-column;
    overflow: hidden;
    @include sm-desktop-up {
      background: var(--bg-primary-webapp);
    }
    padding: 12px 28px 28px;
    min-height: 300px;   
    .desc {
      @include f-inter-regular(1.5rem, 2.1rem);
      padding: 0px 0 24px;
    }
    .profile {
      &Title {
        @include f-inter-semi-bold(1.6rem, 2rem);
      }
      &Button {
        padding: 0;
        color: var(--link-color);
        @include f-inter-semi-bold(1.4rem, 1.8rem);
      }
      &Details {
        border-radius: 10px;
        padding: 12px 16px;
        margin-top: 8px;
        @include sm-desktop-down {
          background: var(--bg-primary-webapp);
        }
        @include sm-desktop-up {
          background: var(--bg-primary);
        }
        &Row {
          column-gap: 10px;
        }
        .label {
          @include f-inter-regular(1.6rem, 2.8rem);
        }
        .value {
          @include f-inter-regular(1.4rem, 2rem);
          color: var(--dark-grey);
          white-space: pre-wrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
        .separator {
          margin: 10px 0;
          border-bottom: 1px dashed #ced0d4;
        }
      }
    }
  }
  .section {
    position: sticky;
    .bottom {
      bottom: 0;
    }
    .top {
      top: 0;
    }
    .separator {
      @include sm-desktop-down {
        display: none;
      }
      border-bottom: 1px solid #ababab;
    }
    .footer {
      background: var(--bg-primary);
      @include sm-desktop-down {
        padding: 0 28px 16px;
      }
      @include sm-desktop-up {
        padding: 28px;
      }
      @include flex(null, flex-end);
      .button {
        @include sm-desktop-down {
          width: 100%;
        }
        @include sm-desktop-up {
          padding: 7px 16px;
        }
      }
    }
    .header {
      background: var(--bg-primary);
      @include sm-desktop-up {
        background: var(--bg-primary-webapp);
      }
      padding: 13px;
      .title {
        @include f-inter-semi-bold(1.6rem, 1.8rem);
        text-align: center;
      }
      .cancelIcon {
        position: absolute;
        top: 12px;
        right: 20px;
        color: var(--primary-color-disabled);
        z-index: $zindex-modal;
        @include sm-desktop-down {
          background: #f8f7f7;
          border-radius: 50%;
          padding: 9px;
        }
      }
    }
  }
}
