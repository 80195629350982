@import 'styles/common.scss';

.applyModal {
  background-color: rgba(0, 0, 0, 0.7);
  div {
    border-radius: 16px;
  }
}
.container {
  position: relative;
  @include desktop {
    width: 360px;
  }
  @include mobile {
    width: 360px;
    flex-shrink: 1;
    max-width: calc(100vw - 24px);
  }
  .content {
    padding: 20px;
  }
  .cancelIcon {
    position: absolute;
    top: 20px;
    right: 20px;
    color: var(--primary-color-disabled);
    z-index: $zindex-modal;
  }
}
