@import 'styles/common.scss';

.container {
  width: 460px;
  @include mobile{
    width: 100%;
  }
  border-radius: 16px;
  position: relative;
  background-color: var(--ui-teal-light-surface-tertiary);
}
