@import 'styles/common.scss';

.CardLogo {
  @include flex;
  padding-top: 5px;

  .host {
    z-index: 2;
  }
  .cohost {
    z-index: 1;
    margin-left: -15px;
  }
  .logo {
    display: flex;
    height: 40px;
    width: 40px;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    border-radius: 100%;
    border: 1px solid var(--border-color-secondary);
    &Container {
      position: relative;
    }
  }
  .verified {
    position: absolute;
    bottom: -4px;
    right: 0;
    color: var(--primary-color);
    border-radius: 100%;
    border: 1px solid var(--bg-primary);
    svg {
      width: 16px;
      height: 16px;
    }
    &Huzzle {
      position: absolute;
      bottom: 4px;
      right: 0;
      background: var(--ui-teal-light-surface-warning);
      padding: 2px;
      border-radius: 100%;
      border: 1px solid var(--bg-primary);
      svg {
        width: 10px;
        height: 10px;
        * > path {
          fill: var(--text-icon-on-lights-warning);
        }
      }
    }
  }
  &Small {
    .logo {
      height: 24px;
      width: 24px;
      min-width: 24px;
      min-height: 24px;
      max-width: 24px;
      max-height: 24px;
    }
    .verified {
      position: absolute;
      bottom: 4px;
      right: -2px;
      svg {
        width: 10px;
        height: 10px;
      }
    }
  }
}
