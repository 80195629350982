@import 'styles/common.scss';

.ApplicationsBanner {
    position: relative;
    height: 48px;
    padding: 8px 20px;
    border-bottom: 1px solid var(--border-on-lights-10);
    background: var(--surface-brand-silent);
    @include flex-center;

    @include small {
        height: auto;
        padding: 16px;
        flex-direction: column;
        position: fixed;
        top: 48px;
        width: 100%;
        z-index: 1;
    }

    .bannerInfo {
        @include flex-center;

        @include small {
            margin-bottom: 8px;
            @include flex(flex-start, space-between);
        }

        .magicIcon {
            margin-right: 10px;

            svg {
                path {
                    fill: var(--primary-color);
                }
            }
        }

        p {
            color: var(--primary-color);
            @include f-inter-regular-2(1.4rem, 2rem);

            @include small{
                padding-right: 24px
            }
        }
    }

    .bannerAction {
        position: absolute;
        right: 20px;
        @include flex-center;

        @include small {
            position: static;
        }

        .coverLetterAction {
            margin-right: 6px;

            & > button > button {
                background: inherit;
                border: none;
                padding: 0;

                & > button > div {
                    &:nth-child(2) {
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }

        .coverLetterMenuList {
            bottom: unset;

            @include mobile {
                right: unset;

                li > button > div {
                    white-space: normal;
                    height: auto;
                    text-align: start;
                }
            }
        }

        .bannerTooltip {
            width: 215px;
            left: -30px;
            border-radius: 12px;
            padding: 8px 12px;
            white-space: break-spaces;
        }
    }

    .closeBannerIcon {
        margin-left: 15px;

        @include small {
            margin-left: 4px;
            position: absolute;
            right: 16px;

            svg {
                width: 14px;
                height: 14px;
            }
        }
    }
}
