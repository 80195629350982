@import 'styles/common.scss';

.MultiSelect {
  position: relative;
  white-space: nowrap;
  @include flex-column(null, null);
  &Trigger {
    @include fns-semi-bold(1.4rem, 2.2rem);
    @include flex(center);
    column-gap: 5px;
    border: 0;
    background: var(--bg-primary);
    color: var(--text-secondary);
    border-radius: 10px;
    padding: 4px 12px;
    border: 1px solid transparent;
    cursor: pointer;
    @include small {
      padding: 4px 8px;
    }
    &:disabled {
      color: var(--text-tertiary);
    }
    &FullWidth {
      width: 100%;
      p {
        width: 100%;
      }
    }
    &HasBorder {
      border: 1px solid var(--border-color);
      @include sm-desktop-up {
        border: none;
      }
    }
  }
  &SelectedCount {
    span {
      @include fns-bold(1.2rem, 1.9rem);
      padding: 2px 8px;
      background: var(--primary-color);
      color: var(--accent-text);
      margin-left: 8px;
      border-radius: 25%;
      @include small {
        padding: 2px 6px;
        margin-left: 4px;
      }
    }
  }
  &Icon {
    margin-left: 6px;
    &Reverse {
      transform: rotateX(180deg);
    }
    &,
    &Reverse {
      transition: transform 0.2s;
    }
  }
  &Dropdown {
    position: relative;
    width: auto;
    background: var(--bg-primary);
    border-radius: 10px;
    box-shadow: 0px 0px 10px var(--shadow-color);
    z-index: $zindex-dropdown;
    min-width: 272px;
    &Align {
      &Left {
        left: 0;
      }
      &Right {
        right: 0;
      }
      &Center {
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &Search {
      padding: 24px 20px 0 24px;
      .MultiSelectDropdownSearchInput {
        color: var(--text-primary);
        input {
          @include fns-regular(1.6rem, 1.8rem);
          padding: 10px 48px 10px 48px;
        }
      }
    }
    &Options {
      padding: 0 12px;
      margin: 16px 16px 16px 0;
      height: auto;
      max-height: 292px;
      overflow: auto;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: var(--scroll-bar-color);
        box-shadow: 0 0 1px rgba($color: $black, $alpha: 0.5);
      }
      li {
        @include flex(center);
        color: var(--text-primary);
        padding: 5px 24px 5px 12px;
        width: 100%;
        margin-bottom: 4px;
        border-radius: 10px;
        cursor: default;
        @include small {
          padding-right: 0;
        }
        &:hover {
          background: var(--bg-secondary);
        }
      }
    }
    &Option {
      &Selected {
        background: var(--bg-secondary);
      }
      &CheckboxDisabled {
        input {
          opacity: 40%;
        }
        label {
          opacity: 40%;
        }
      }
      &Checkbox {
        width: 100%;
        label {
          @include fns-regular(1.4rem, 1.6rem);
          color: var(--text-primary);
          width: 100%;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:disabled {
            color: red;
          }
        }
        img {
          margin: 0 6px 0 2px;
          height: 18px;
          width: auto;
        }
      }
    }
    &NoResults {
      @include fns-regular(1.6rem, 1.9rem);
      padding: 0 14px 20px;
    }
    &Footer {
      @include flex(center, flex-end);
      padding: 12px 24px;
      border-top: 0.5px solid var(--border-color);
    }
    top: -45px;
    margin-bottom: -40px;
    &FullWidth {
      width: 100%;
    }
  }
  .valuesEllipsis {
    @include multi-line-ellipsis(1);
    text-align: left;
  }
  &FullWidth {
    width: 100%;
  }
}
