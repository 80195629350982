@import 'styles/common.scss';

.RegistrationForm {
  position: relative;
  .content {
    position: relative;
    @include flex-column(null, null);
    padding: 12px 28px 28px;
    @include sm-desktop-up {
      background: var(--bg-primary-webapp);
    }
    .list {
      ul {
        z-index: 111;
      }
    }
    .desc {
      @include f-inter-regular(1.5rem, 2.1rem);
      padding-top: 0;
      padding-bottom: 8px;
    }
    .question {
      position: relative;
    }
    .input {
      padding: 8px 0;

      label {
        font-weight: 500;
      }

      & > button {
        @include sm-desktop-down {
          border: 1px solid var(--border-color);
        }
      }
    }
    .input,
    .otpinput {
      * > input {
        @include sm-desktop-up {
          border: none;
        }
      }
    }
    .isInvalid {
      * > input {
        border: 1px solid var(--complementary-secondary);
        border-radius: 10px;
      }
    }
    .studyyear {
      padding: 8px 0;
    }
    .label,
    .labelTitle {
      @include f-inter-regular(1.5rem, 2.2rem);
      color: var(--text-primary);
    }
    .labelTitle {
      padding: 8px 0 4px;
      align-self: center;
    }
    .resume {
      @include f-inter-regular(1.5rem, 2.2rem);
      background: var(--bg-primary);
      padding: 11px;
      border-radius: 10px;
      white-space: pre-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    .textarea {
      min-height: 48px;
      max-height: 98px;

      & > textarea {
        min-height: 48px;
        width: 100%;
        height: 100%;
        resize: vertical;
        @include sm-desktop-up {
          border: none;
        }
      }
      &.paragraph {
        min-height: 104px;
        max-height: 400px;
        & > textarea {
          min-height: 104px;
        }
      }
    }
    .tick {
      color: var(--primary-color);
      margin-top: 5px;
    }
    .policy {
      column-gap: 10px;
      padding-top: 8px;
      &Title {
        @include f-inter-regular(1.6rem, 2.8rem);
        & > span > * {
          @include f-inter-bold(1.6rem, 2.8rem);
        }
      }
    }
    .optionalLabel {
      @include f-inter-semi-bold(1.1rem, 1.3rem);
      color: var(--dark-grey);
    }
  }
  .contentOTP {
    @include flex-column(null, flex-start);
    min-height: 150px;
  }
  .section {
    position: sticky;
    &.bottom {
      bottom: 0;
    }
    &.top {
      top: 0;
    }
    z-index: 101;
    background-color: #ffffff;
    .separator {
      @include sm-desktop-down {
        display: none;
      }
      border-bottom: 1px solid #ababab;
    }
    .footer {
      background: var(--bg-primary);
      @include sm-desktop-down {
        padding: 10px 28px 16px;
      }
      @include sm-desktop-up {
        padding: 28px;
      }
      padding: 28px;
      @include flex(null, flex-end);
      .button {
        @include sm-desktop-down {
          width: 100%;
        }
        @include sm-desktop-up {
          padding: 7px 16px;
        }
      }
    }
    .header {
      background: var(--bg-primary);
      @include sm-desktop-up {
        background: var(--bg-primary-webapp);
      }
      padding: 13px;
      @include flex(center, space-between);
      .title {
        @include f-inter-semi-bold(1.6rem, 1.8rem);
        text-align: center;
      }
      .cancelIcon {
        color: var(--primary-color-disabled);
        z-index: $zindex-modal;
        @include sm-desktop-down {
          background: #f8f7f7;
          border-radius: 50%;
          padding: 9px;
        }
      }
      .back {
        color: var(--primary-color-disabled);
        z-index: $zindex-modal;
        padding: 0;
        &ButtonHidden {
          visibility: hidden;
        }
        &Icon {
          width: 24px;
          height: 24px;
        }
        @include sm-desktop-down {
          background: #f8f7f7;
          border-radius: 50%;
          padding: 3px;
        }
      }
    }
  }

  .customQuestionContainer {
    padding: 8px 0;
  }

  .multiSelectTrigger {
    padding: 10px 16px 10px 16px;
    overflow: hidden;
    white-space: nowrap;
    p {
      @include f-inter-regular(1.5rem, 2.2rem);
      color: rgba(9, 20, 37, 0.5);
    }

    div {
      width: 18px;
      height: 18px;
      color: rgba(9, 20, 37, 0.74);
    }
  }
  .selectedValue {
    p {
      color: var(--black-color);
    }
  }
  .dropdownV2Options {
    top: calc(100% - 50px);
  }
}
