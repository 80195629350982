@import 'styles/common.scss';
.locationModal {
  padding: 20px;
  &Content {
    position: relative;
    padding: 20px;
    width: 360px;
  }
  .emoji {
    @include f-inter-regular(3.2rem, 2.5rem);
    text-align: center;
  }
  .close {
    position: absolute;
    right: 20px;
    top: 10px;
    color: var(--dark-grey);
  }
  .title {
    @include f-inter-bold(1.8rem, 2.2rem);
    text-align: center;
    padding: 24px 0;
  }
  .detail {
    @include f-inter-regular(1.5rem, 2.1rem);
    color: rgba(9, 20, 37, 0.74);
    padding-bottom: 32px;
    mark {
      font-weight: 600;
      background: transparent;
    }
  }
  .apply {
    background: none;
    color: var(--text-primary);
    margin: 0 auto;
    box-shadow: none;
  }
}
.Card {
  border-radius: 12px;
  column-gap: 12px;
  
  @include media-breakpoint-small {
    padding: 16px;
  }
  @include media-breakpoint-medium-only {
    padding: 16px;
  }
  @include media-breakpoint-large {
    padding: 16px 20px 20px;
  }
  &-white {
    background: var(--ui-teal-light-bg-primary);
    box-shadow: 0px 1px 3px 0px var(--surface-overlay-silent);
    &:hover {
      background: var(--ui-teal-light-surface-tertiary-hover);
      box-shadow: 0px 2px 3px 0px var(--border-on-lights-06);
    }
  }
  &-secondary {
    background: var(--surface-overlay-silent);
    &:hover {
    background: var(--surface-overlay-loud);

    }
  }
  .header {
    margin-bottom: 4px;
    .type {
      @include f-inter-bold(1.7rem, 2.2rem);
      font-size: 13px;
      line-height: 1rem;
    }
    .event {
      color: #df303b;
    }
    .job {
      color: #009479;
    }
    .internship {
      color: #1d7bc3;
    }
    .dot {
      margin: 0 5px;
      color: var(--dot-color);
    }
    .date {
      @include f-inter-bold(1.3rem, 1.6rem);
      color: #ec3843;
      text-transform: uppercase;
    }
    .newDate {
      color: var(--text-icon-inverted-secondary);
    }
  }
  .title {
    @include f-inter-semi-bold(1.6rem, 2.2rem);
    letter-spacing: -0.33px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .info {
    margin-top: 2px;
    width: 100%;
  }
  .text {
    display: inline;
    @include f-inter-regular-2(1.3rem, 1.5rem);
    color: var(--text-icon-on-lights-secondary);
    &Company {
      @include mobile {
        max-width: 150px;
      }
    }
  }
  .hostsInfo {
    @include mobile {
      max-width: 200px;
    }
    @include desktop {
      max-width: 100%;
    }
    }
  .createdAtInfo,
    .touchpointType {
    display: inline-flex;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .datePosted {
    @include f-inter-regular-2(1.3rem, 1.5rem);
    color: var(--text-icon-on-lights-secondary);
  }
  .dot {
    margin: 0 5px;
    @include f-inter-regular-2(1.3rem, 1.5rem);
    color: var(--dot-color);
  }
  .dotSvg {
    margin: 7px 4px;
    height: 2px;
    width: 2px;
    color: #080c0b9e;
    min-width: initial;
  }
  .newSetDot {
    margin: 7px 4px;
    height: 2px;
    width: 2px;
    color: #ffffffe0;
  }
  .salary {
    @include f-inter-regular-2(1.4rem, 2rem);
    background: var(--surface-overlay-silent);
    border-width: 0;
    color: var(--text-icon-on-lights-tertiary);
    border-radius: 8px;
    padding: 4px 8px;
    &:last-of-type {
      margin-right: 0;
    }
  }
  .detail {
    margin-top: 8px;
    column-gap: 4px;
    row-gap: 4px;
  }
  .kind {
    @include f-inter-regular-2(1.4rem, 2rem);
    color: var(--text-icon-on-lights-tertiary);
    background: var(--surface-overlay-silent);
    border-radius: 8px;
    padding: 4px 8px;
  }
  .expiredChip {
    @include f-inter-regular-2(1.4rem, 2rem);
    border-radius: 8px;
    background: #ffe4e4;
    padding: 4px 8px;
    color: var(--text_icon-on_lights-critical);
  }
  .deadline {
    @include f-inter-regular-2(1.4rem, 2rem);
    border-radius: 8px;
    padding: 4px 8px;
    border-width: 0;
    gap: 5px;
    div {
      width: 14px;
      height: 14px;
      min-width: 14px;
    }
  }
  .location {
    @include flex(center);
    .chipButton {
      margin-left: 4px;
    }
  }
  .easyApply {
    @include flex-center;
    gap: 5px;
    svg {
      color: #ffcc01;
    }
  }

  .progress {
    margin-top: 20px;
    &Container {
      @include flex(flex-start, space-between);
      padding: 10px 20px;
      gap: 4px;
      align-self: stretch;
      border-radius: 12px;
      background: var(--surface-overlay-minimal);
      margin-bottom: 12px;
      @include media-breakpoint-small {
        display: none;
      }
      .step {
        padding: 8px 0px;
        text-align: center;
        flex: 1;
        @include mobile {
          width: 100%;
        }
        &:not(:last-child) {
          margin-right: 4px;
        }

        .heading {
          @include flex;
          text-align: left;
          margin-bottom: 8px;
          .icon {
            padding: 4px 7px;
            border-radius: 10px;
            background: var(--surface-brand-silent);
            color: $primary-color;

            &Text {
              padding: 4px 5px;
            }
            @include f-inter-semi-bold(1rem, 1rem);
          }
          .title {
            align-self: center;
            color: rgba(8, 12, 11, 0.62);
            margin-left: 4px;
            @include f-inter-regular-2(1.1rem, 1.5rem);
          }
        }

        .progressBar {
          height: 4px;
          width: 100%;
          border-radius: 8px;
          background: rgba(8, 12, 11, 0.05);

          &.active {
            background-color: var(--primary-color);
          }
        }
      }
    }
    &Content {
      @include flex(flex-start, space-between);
      column-gap: 4px;
      padding: 8px 20px;
      @include media-breakpoint-small {
        flex-direction: column;
        row-gap: 10px;
        background: var(--surface-overlay-minimal);
        border-radius: 8px;
      }
      .title {
        color: var(--text-primary-light-v2);
        @include f-inter-semi-bold(1.4rem, 1.8rem);
      }

      .description {
        color: var(--text-icon-on-lights-tertiary);
        @include f-inter-regular-2(1.2rem, 1.6rem);
        padding-top: 4px;
        .downloadlink {
          @include f-inter-regular-2(1.2rem, 1.6rem);
          color: var(--link-color);
        }
      }
      .buttonGroup {
        @include flex(flex-start, space-between);
        gap: 8px;
        .button {
          padding: 10px 16px;
        }
        .iconLeft {
          margin-right: 0px;
          width: 18px;
          height: 18px;
        }
        .trigger {
          position: relative;
          @include flex;
          cursor: pointer;
          width: 100%;
          border-radius: 10px;
          border: 1.5px solid var(--border-on-lights-15);
          background: var(--ui-teal-light-bg-primary);
          box-shadow: 0px 1px 3px 0px rgba(8, 12, 11, 0.05);
          &:hover {
            background: var(--ui_teal_light-surface-primary-pressed);
          }
          button {
            border: none;
            background: transparent;
          }
          &Align {
            right: -50px;
          }
        }
        .quick-apply {
          color: #ffcc01;
        }
      }
    }
  }
  .huzzleVerified {
    border: 1px solid var(--primary-color);
  }
  .newSet {
    color: var(--accent-text);
    & > p {
      color: var(--accent-text);
    }
  }
}
.expired {
  background: transparent;
  border-radius: 0;
}
.EntityCard {
  @include flex(center);
}
.link {
  flex: 1;
}

.registrationModal {
  @include sm-desktop-down {
    bottom: 50px;
  }
}

.magicWandIcon {
  color: rgba(8, 12, 11, 1);
  margin: 8.5px 7px;
}
