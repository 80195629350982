@import "styles/common.scss";

.notification {
  text-decoration-line: underline;
  cursor: pointer;
}

.message-only {
  padding: 3px 0;
}
