@import "styles/common.scss";

.ExternalApply {
  .header {
    @include flex;
    flex-direction: row-reverse;
    @include desktop {
      min-width: 460px;
    }
    @include mobile{
        width: 100%;
      }
    min-height: 48px;
    border-radius: 10px, 10px, 0px, 0px;
    .headerWrapper {
      @include flex(center);
      width: 100%;
      padding: 10px;
      margin-left: 7%;
      .title {
        @include f-inter-semi-bold(1.6rem, 2.2rem);
        text-align: center;
        margin: auto;
      }
    }
  }
  .imageContainer {
    padding: 8px 28px;
    img{
        @include mobile{
            width: 100%;
            max-height: 137px;
        }
        object-fit: fill;
    }
    .contentContainer {
      color: var(--text-icon-on-lights-secondary);
      text-align: center;
      @include f-inter-regular(1.5rem, 2.4rem);
      margin: 20px 0;
      .blueText{
        color: var(--primary-color);
      }
    }
    .modalCheckbox{
      margin-left:15px;
      label{
        color: var(--text-icon-on-lights-secondary);
        @include f-inter-regular-2(1.4rem,2rem);
        @include mobile{
            margin: 4px;
        }
      }
    }
  }
  .closeIcon {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &Header {
    padding: 24px 48px 12px 28px;
    @include flex(flex-start);

    .touchpointInfo {
      margin-left: 12px;

      .title {
        margin-bottom: 4px;
        @include f-inter-semi-bold(1.6rem, 2.2rem);
      }

      .details {
        margin-bottom: 12px;
        @include f-inter-regular-2(1.3rem, 1.8rem);
        @include flex(center);

        .dots {
          width: 2px;
          height: 2px;
          margin: 0 4px;
          background: var(--text-icon-on-lights-tertiary);
          border-radius: 50%;
        }
      }

      .kind {
        padding: 4px 8px;
        border-radius: 16px;
        border: 0.5px solid var(--border-on-lights-15);
        background: var(--surface-overlay-minimal);
        @include f-inter-regular-2(1.3rem);
        @include flex-center;
        display: inline-flex;
      }
    }
  }

  &Content {
    @include flex();
    padding: 28px;
    @include mobile{
      padding: 16px 28px;
    }
    gap: 8px;
    @include mobile{
        @include flex();
        flex-direction: column-reverse;
    }
    .info {
      margin-bottom: 16px;
      color: var(--text-icon-on-lights-tertiary);
      @include f-inter-regular-2(1.2rem, 1.7rem);
    }
  }
}
